/* General container for profile card */
.profilepage-card-container {
  width: 95%;
  max-width: 500px;
  min-width: 300px;
  margin-top: 0px;
  margin-bottom: 50px;
  padding: 10px;
  padding-bottom: 20px;
  background-color: silver;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: none;
}

.profilepage-header-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  /* Add spacing between buttons and the search input */
}

.profilepage-header-button {
  background: none;
  border: none;
  color: gray;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
  transition: color 0.3s ease;
}

.profilepage-header-button:hover {
  color: gold;
}

.profilepage-header-button:active {
  color: white;
}

.profilepage-header-button:focus {
  outline: none;
  /* Remove focus outline for a clean look */
}

/* Profile card header */
.profilepage-card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: rgb(39, 39, 39);
  color: white;
  padding: 10px;
  position: relative;
}

.youtube-link-container {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
}

.youtube-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: #990000;
  color: #fff;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.youtube-link:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5);
}

.youtube-icon {
  font-size: 17px;
}

.profilepage-search-input {
  width: 150px;
  background-color: #292929;
  color: gray;
  border: 1px solid #444;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.profilepage-search-input::placeholder {
  color: gray;
}

.profilepage-settings-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
}

.profilepage-settings-icon {
  font-size: 20px;
}

/* Profile card content */
.profilepage-card-content {
  padding: 20px;
  border-radius: 5px;
  background-color: rgb(39, 39, 39);
  color: white;
}

/* Avatar and Badge container */
.profilepage-avatar-badge-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
}

.profilepage-avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 150px;
  position: relative;
}


.profilepage-avatar-image {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  object-fit: cover;
  /* Ensures the image covers the circle */
  border: 2px solid white;
  background: black;
  position: relative;
  z-index: 1;
  /* Default z-index */
  margin-bottom: 10px;
}

.profilepage-avatar-image:hover {
  transform: scale(2);
  /* Enlarges the image slightly */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.8);
  /* Creates a darker shadow effect */
  z-index: 1000 !important;
  /* Default z-index */
}

.profilepage-handle-container h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.profilepage-badge-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  gap: 10px;
  justify-items: center;
}

.profilepage-badge-image {
  width: 40px;
  height: auto;
  transition: transform 0.3s ease;
}

.profilepage-badge-image:hover {
  transform: scale(1.1);
}

.profilepage-locked-badge {
  filter: grayscale(100%);
  opacity: 0.5;
}

.profilepage-current-badge-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  margin-top: 10px;
}

.profilepage-current-badge-image {
  width: 100%;
  height: auto;
  max-width: 150px;
}

.profilepage-current-badge-label {
  margin-top: 5px;
  color: white;
  font-size: 16px;
  text-align: center;
}

/* Journey completion section */
.profilepage-journey-completion-container {
  margin-top: 10px;
  width: 100%;
}

.profilepage-journey-completion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.profilepage-journey-completion-label {
  font-size: 14px;
  color: white;
  font-weight: bold;
}

.profilepage-journey-completion-percentage {
  font-size: 16px;
  color: white;
  text-align: right;
}

.profilepage-journey-completion-bar {
  width: 100%;
  background-color: #ccc;
  border-radius: 5px;
  overflow: hidden;
  height: 10px;
  margin-top: 5px;
}

.profilepage-journey-completion-fill {
  height: 10px;
  background-color: #4caf50;
}

/* Progress card container */
.profilepage-progress-card-container {
  margin-top: 20px;
}

.profilepage-heart-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease, -webkit-text-stroke 0.3s ease;
}

.profilepage-heart-icon {
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.profilepage-heart-icon.unfavorited {
  color: black;
  background-color: transparent;
  -webkit-text-stroke: 1px white;
}

.profilepage-heart-icon.favorited {
  color: white;
  background-color: transparent;
  -webkit-text-stroke: none;
}

.profilepage-heart-icon:hover {
  color: goldenrod;
}

/* General responsive adjustments for smaller screens */
@media (max-width: 768px) {

  /* Container adjustments */
  .profilepage-card-container {
    padding: 5px;
    max-width: 90%;
  }

  /* Avatar adjustments */
  .profilepage-avatar-container {
    max-width: 100px;
    /* Reduce the maximum width */
  }

  .profilepage-avatar-image {
    width: 80px;
    /* Reduce avatar size */
    height: 80px;
    border-width: 1px;
    /* Thinner border */
  }

  /* Current badge adjustments */
  .profilepage-current-badge-container {
    margin-left: 5px;
    margin-top: 5px;
  }

  .profilepage-current-badge-image {
    max-width: 80px;
    /* Reduce badge size */
  }

  .profilepage-current-badge-label {
    font-size: 12px;
    /* Smaller text size */
  }

  /* Badges grid adjustments */
  .profilepage-badge-container {
    grid-template-columns: repeat(2, 1fr);
    /* Keep the grid layout */
    gap: 5px;
    /* Reduce spacing between badges */
  }

  .profilepage-badge-image {
    width: 30px;
    /* Smaller badge images */
  }

  /* Journey completion text adjustments */
  .profilepage-journey-completion-label,
  .profilepage-journey-completion-percentage {
    font-size: 12px;
    /* Reduce text size */
  }

  /* Journey completion bar adjustments */
  .profilepage-journey-completion-bar {
    height: 8px;
    /* Thinner progress bar */
  }

  /* Text adjustments for smaller screens */
  .profilepage-handle-container h3 {
    font-size: 14px;
    /* Smaller username text */
  }

  .profilepage-search-input {
    width: 120px; /* Reduce width for smaller devices */
  }

  .profilepage-header-button {
    font-size: 12px; /* Smaller font size for buttons */
    padding: 2px 5px; /* Adjust padding for smaller buttons */
  }
}


@media (min-width: 768px) {

    /* YouTube link adjustments */
    .youtube-link {
      width: 36px; /* Increase size */
      height: 36px;
    }
  
    .youtube-icon {
      font-size: 20px; /* Make the icon slightly larger */
    }
  
    /* Heart icon adjustments */
    .profilepage-heart-icon {
      font-size: 28px; /* Increase the heart icon size */
    }
    
  /* General container adjustments */
  .profilepage-card-container {
    width: 90%;
    max-width: 600px; /* Increase max width */
    padding: 20px; /* Add more padding for a spacious layout */
  }

  /* Avatar adjustments */
  .profilepage-avatar-container {
    max-width: 200px; /* Increase max width of avatars */
    margin-right: 35px;
  }

  .profilepage-avatar-image {
    width: 170px; /* Larger avatar size */
    height: 170px;
    border-width: 3px; /* Thicker border */
  }

  /* Current badge adjustments */
  .profilepage-current-badge-container {
    margin-left: 15px; /* Add more space for badges */
    margin-top: 15px;
  }

  .profilepage-current-badge-image {
    max-width: 200px; /* Larger badge image */
  }

  .profilepage-badge-image {
    width: 50px; /* Larger badge images */
    height: 50px;
  }

  /* Text adjustments */
  .profilepage-handle-container h3 {
    font-size: 18px; /* Bigger username text */
  }

  .profilepage-current-badge-label {
    font-size: 18px; /* Increase badge label text size */
  }

  .profilepage-search-input {
    width: 200px; /* Wider search input */
    font-size: 19px; /* Larger text size */
  }

  .profilepage-header-button {
    font-size: 18px; /* Bigger button font */
    padding: 5px 10px; /* Adjust padding for buttons */
  }


  /* Journey completion adjustments */
  .profilepage-journey-completion-label,
  .profilepage-journey-completion-percentage {
    font-size: 18px; /* Bigger text size */
  }

  .profilepage-journey-completion-bar {
    height: 12px; /* Thicker progress bar */
  }
}
