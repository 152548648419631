.iconContainer {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Adjust this margin as needed for spacing between icon and text */
}

.icon {
  font-size: 15px; /* Adjust the font size as needed */
  margin-right: 10px; /* Adjust this margin as needed for spacing between icon and text */
}

.card {
  position: relative;
  padding: 10px;
  border-radius: 5px;
  min-width: 320px;
  margin: 0 auto;
  font-size: 12px;
  line-height: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: silver;
  margin-bottom: 50px;
}

.cardTable {
  width: 100%;
  border-collapse: collapse;
}

.cardTable th,
.cardTable td {
  padding: 3px 15px;
  text-align: center;
  border: 0.1px solid #ddd;
  font-size: 12px;
}

.progressionCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Center align the table headers */
.cardTable th {
  text-align: center; 
  background-color: rgb(39, 39, 39); /* Set the background color for the user column */
  color: white;
}

/* Center align the icon and text in the header cells */
.levelHeader .iconContainer {
  justify-content: center;
}

/* Background colors for different levels */
.level-1 {
  background-color: black; /* Set the background color for Level 1 */
  color: white; /* Set the text color for Level 1 */
}

.progressionCell:hover {
  color: black !important; /* Change the text color on hover to a nicer color */
  background-color: yellow !important;
  cursor: pointer !important;
}

.progressionCell:hover {
  color: black !important; /* Change the text color on hover to a nicer color */
  background-color: yellow !important;
  cursor: pointer !important;
}

.progressionCell:hover .watchVideoButton{
  color: black !important; /* Change the text color on hover to a nicer color */
  background-color: yellow !important;
  cursor: pointer !important;
}

/* Header styles */
.cardHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
  background-color: rgb(39, 39, 39);
  color: white;
  padding-top:20px;
  padding-bottom:5px;
}

.cardHeader img {
  height: 60px;
  margin-right: 20px;
  margin-top: -10px;
}

.cardHeader span {
  font-weight: bold;
  font-size: 18px;
}

.subtitle {
  font-size: 14px;
  margin-top: 10px;
  color: rgb(255, 255, 255); /* Adjust the color as needed */
}

/* Styling for the download button */
.downloadButton {
  cursor: pointer;
  background-color: #ffffff; /* Change the background color as desired */
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: background-color 0.3s ease;
  margin-left: 20px;
  margin-bottom: 15px;
}

.downloadButton:hover {
  background-color: #717171; /* Change the background color on hover */
  color: rgb(255, 255, 255);
}

/* Styles for the video modal */
.videoPopup {
  position: fixed; /* Position the modal absolutely on the viewport */
  top: 50%; /* Center the modal vertically */
  left: 50%; /* Center the modal horizontally */
  transform: translate(-50%, -50%); /* Center the modal precisely */
  z-index: 1000000; /* Ensure the modal is on top of other elements */
  background-color: rgba(0, 0, 0, 0.9); /* Semi-transparent background */
  padding: 0px; /* Add padding for the video content */
  border-radius: 10px;
  width: 90%; /* Set the maximum width to 90% of the viewport width */
  height: clamp(50%, 75%, 100%);
  overflow: none; /* Add scrollbars if the content exceeds the max dimensions */
}

/* Style the content inside the modal to fit within the modal */
.videoPopup iframe {
  width: 100%; /* Ensure the iframe takes up the full width */
  height: 100%
}

/* Style for the close button */
.videoCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
  font-size: 24px;
  z-index: 1001; /* Ensure the close button is on top of the modal */
}

/* Styles for the "Coming soon!" message and logo */
.comingSoonMessage {
  text-align: center;
  font-size: clamp(15px, 10vw, 30px);
  margin-top: 30px; /* Adjust the margin as needed */
  color: #fff; /* Adjust the text color as needed */
}

.comingSoonMessageSubtitle {
  text-align: center;
  font-size: clamp(12px, 10vw, 25px);
  margin-top: 20px; /* Adjust the margin as needed */
  margin-bottom: 30px; /* Adjust the margin as needed */
  color: #fff; /* Adjust the text color as needed */
}

.logo {
  display: block;
  margin: 0 auto;
  width: clamp(200px, 20vw, 800px);
  margin-top: 30px; /* Add margin to separate the logo from the text */
}

.watchVideoButton {
  background-color: transparent;
  border: none;
  text-decoration: none; /* Remove underlines */
  cursor: pointer;
  float: right !important;
}

.watchVideoIcon {
  font-size: 20px; /* Adjust the icon size as needed */
}

.badgeImage {
  display: block;
  margin: 0 auto 5px; /* Center the badge and add space below it */
  width: 40px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}


.badgeImage:hover {
  transform: scale(2);
  z-index: 1000;
}

/* Styles for wider screens */
@media (min-width: 768px) {
  .card {
    padding: 20px;
    border-radius: 10px;
    min-width: 500px;
    line-height: 1.2; /* Adjust line-height */
  }

  .cardHeader img {
    height: 80px; /* Increase the header image size */
    margin-right: 30px;
  }

  .cardHeader span {
    font-size: 24px; /* Increase font size for the header title */
  }

  .subtitle {
    font-size: 18px; /* Increase subtitle size */
  }

  .downloadButton {
    width: 50px; /* Increase size of download button */
    height: 50px;
    font-size: 24px;
    margin-left: 30px;
  }

  .badgeImage {
    width: 60px; /* Increase badge size */
  }

  .cardTable th,
  .cardTable td {
    padding: 5px 20px; /* Increase padding for table cells */
    font-size: 16px; /* Increase font size in table */
  }

  .videoPopup {
    width: 75%; /* Increase video popup width */
    height: 75%; /* Increase video popup height */
  }

  .comingSoonMessage {
    font-size: 24px; /* Increase size of "Coming soon" message */
  }

  .comingSoonMessageSubtitle {
    font-size: 20px; /* Increase subtitle size */
  }

  .logo {
    width: 300px; /* Adjust logo size */
  }

  .watchVideoIcon {
    font-size: 24px; /* Increase video icon size */
  }
}