.page {
  padding-top: 76px !important; /* Adjust this value based on your Navbar's height */
  color: white;
}

html {
  margin:0 !important;
  padding:0 !important;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(0, 0, 0, 1) !important;
  margin:0 !important;
  padding:0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container-fluid{
  margin:0 !important;
  padding:0 !important;
}

.row{
  margin:0 !important;
  padding:0 !important;
}

/******Scrollbar*****/

/* For Webkit (Chrome, Safari) */
/* Customize the track and thumb styles */
body::-webkit-scrollbar {
  width: 10px; /* Adjust the width as needed */
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(120, 120, 120, 0.5); /* Color of the thumb */
  border-radius: 4px; /* Rounded corners for the thumb */
}

body::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the track */
}

/* For Firefox */
/* Customize scrollbar width and color */
body {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(120, 120, 120, 0.5) transparent; /* thumb and track color */
}

/* Ensure the scrollbar is always visible */
body {
  overflow-y: scroll; /* Always show the scrollbar when there's overflow */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(120, 120, 120, 0.5) transparent; /* thumb and track color */
}