.noteStyle {
  text-align: center;
  font-size: 16px;
  color: rgb(255, 255, 255);
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoIcon {
  color: white; /* Set the icon color to white */
  margin-right: 8px; /* Add some spacing to the right of the icon */
  vertical-align: middle; /* Align the icon vertically with the text */
  font-size: 24px; /* Adjust the icon size as needed */
}

/* Centering the text and removing background from buttons */
.signed-in {
  text-align: center;
}

/* Centering the text and removing background from buttons */
.signed-in h1 {
  margin-top: 30px;
}

.signed-in-button {
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  color: #ffffff; /* Text color (you can adjust this to your preference) */
}

/* Optional: Add some space between the buttons */
.signed-in-button + .signed-in-button {
  margin-left: 10px;
}

/* Ensure modals are on top of everything */
.modal-backdrop {
  z-index: 1050 !important;
}

.modal {
  z-index: 1060 !important;
}

/* Dark theme modal styling */
.modal-header {
  background-color: #272727; /* Dark background */
  color: white; /* White text */
}

.modal-footer .btn-secondary {
  background-color: #6c757d; /* Secondary button background */
}

.modal-footer .btn-danger {
  background-color: #dc3545; /* Danger button background */
}

/* Modal body styling */
.modal-body {
  background-color: #333333; /* Dark background for modal body */
  color: white; /* White text */
}


.center-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Adds space between buttons */
}

.center-button-container .home-link {
  width: 200px; /* Ensures buttons have consistent width */
  text-align: center;
}

.mock-sign-link {
  display: inline-block;
  padding: 8px 16px; /* Smaller padding */
  margin-bottom: clamp(5px, 2vw, 10px);
  margin-top: 30px;
  background-color: rgba(0, 0, 0, 0.5); /* Same background as .home-link */
  color: white;
  border-radius: 20px; /* Slightly smaller radius */
  text-decoration: none;
  border: 0.5px solid white;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: clamp(16px, 2.5vw, 24px); /* Smaller font size */
  max-width: 100%;
  text-align: center;
}

.mock-sign-link:hover {
  background-color: white; /* Same hover effect */
  color: black;
}

.mock-signin-form {
  margin-top: 20px; /* Add space above the form elements */
}

.mock-signin-button {
  display: block;
  margin: 20px auto 0; /* Add space above the button and center it */
  padding: 8px 16px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 20px;
  text-decoration: none;
  border: 0.5px solid white;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: clamp(16px, 2.5vw, 20px);
  text-align: center;
}

.mock-signin-button:hover {
  background-color: white;
  color: black;
}