.home-logo-text {
  margin-top: 20px;
  height: clamp(80px, 10vw, 100px);
}

.home-logo {
  height: clamp(240px, 20vw, 300px);
  margin-top: 0px;
  margin-bottom: 20px;
}

.home-horizontal-rule {
  width: 75%;
  border: 0.3px solid rgba(255, 255, 255, 0.8); /* White color with 50% transparency */
  margin: 50px auto; /* Center the horizontal rule within its parent */
}

.home-link {
  display: inline-block;
  padding: 10px 20px;
  margin-bottom: clamp(10px, 3vw, 15px);
  margin-top: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 30px;
  text-decoration: none;
  border: 0.5px solid white;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: clamp(20px, 3vw, 30px);
  max-width: 100%; /* Limit the width of the h1 text to 100% of its container */
  text-align: center;
  color: white; /* Text color on the image */
}

.home-link:hover {
  background-color: white;
  color: black;
}

/***** Styles Row 2*******/

/* Style for the background image row */
.home-row {
  min-height: 300px; /* Minimum height for the row */
  margin-bottom: 10px;
  position: relative;
  max-width: 100%; /* Ensure columns don't overflow on smaller screens */
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.home-row.row-1 {
  min-height: 450px; /* Minimum height for the row */
  height: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center content */
  align-items: center; /* Horizontally center content */
}

.home-row.row-program {
  min-height: 700px !important;
}

.home-row.row-train {
  min-height: 750px !important;
}


.home-row-subtitle {
  font-size: clamp(20px, 3vw, 25px);
  max-width: 100%; /* Limit the width of the h1 text to 100% of its container */
  text-align: center;
  color: white; /* Text color on the image */
  margin-bottom: clamp(20px, 3vw, 30px);
}

/* Style for the second video in row 2 */
.home-row-video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -100;
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
}

/* Style for the second video in row 2 */
.home-video {
  width: 80%; /* Set the width to 50% of the window width */
  max-width: 700px;
  margin: 0 auto; /* Center the video horizontally */
  border: 1px solid #ddd; /* Add a border */
  border-radius: 5px; /* Add border-radius */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add box shadow */
}

/* Home.css */
.video-gallery-item {
  width: 100%; /* Set the desired width */
  height: auto; /* Maintain aspect ratio */
}

.training_title {
  color: rgb(255, 255, 255);
  font-size: clamp(20px, 3vw, 25px);
  margin-bottom: 20px;
}

/* Style for individual social media icons */
.social-media-icon-home {
  font-size: 30px; /* Set the width for each icon */
  margin: 0 0px; /* Add margin to separate the icons */
  transition: transform 0.3s ease-in-out; /* Add a smooth hover effect */
  color: white;
  margin-right: 10px;
  margin-left: 10px;
}

/* Style for the icons on hover */
.social-media-icon-home:hover {
  transform: scale(1.5); /* Increase the size of the icon on hover */
  cursor: pointer; /* Add a pointer cursor on hover */
}

.home-about {
  margin-top: 10px;
  font-size: clamp(12px, 3vw, 18px);
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 800px;
  line-height: clamp(30px, 3vw, 35px);
}

.home-logo-text-inline {
  height: clamp(12px, 15vw, 40px);
  padding-right: 5px;
  padding-left: 5px;
}

.home-about-sign {
  text-align: right;
  padding-right: 50px;
}

/* Add styles for the image and text in row 4 */
.home-about-image {
  margin-top: 30px;
  position: relative; /* Set the positioning context */
}

.home-about-image img {
  width: 100%;
}

.home-about-image-text {
  position: absolute;
  top: 0;
  left: 50%; /* Center the text horizontally */
  transform: translateX(-50%); /* Center the text horizontally */
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  width: 100%; /* Stretch the background to 100% */
}

.pashaman {
  position: absolute;
  font-weight: bold;
  color:rgb(255, 255, 255,0.5);
  top: 80%;
  left: 15%; /* Center the text horizontally */
  background-color: rgba(0, 0, 0, 0);
}

.akbar {
  position: absolute;
  font-weight: bold;
  color:rgb(255, 255, 255,0.5);
  top: 70%;
  left: 42%; /* Center the text horizontally */
  background-color: rgba(0, 0, 0, 0);
}

.zarrar {
  position: absolute;
  font-weight: bold;
  color:rgb(255, 255, 255,0.5);
  top: 80%;
  left: 70%; /* Center the text horizontally */
  background-color: rgba(0, 0, 0, 0);
}


.home-bottom {
  margin-bottom: 100px;
}


.home-card-train {
  max-width: 400px; /* Set the maximum width for the card */
  width: 100%; /* Allow it to take up the full width of the gallery item */
  height: auto; /* Maintain aspect ratio */
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  margin: 0 auto; /* Center the card horizontally within the gallery item */
}

.home-link-join{
  color: white;
}

.home-link-join:hover{
  color: rgb(0, 251, 255);
}

