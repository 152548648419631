.iconContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* Ensures right alignment */
}

.icon {
  font-size: 15px;
  margin-right: 10px;
}

.card {
  padding: 10px;
  border-radius: 5px;
  margin: 0 auto;
  font-size: 12px;
  line-height: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: silver;
  margin-bottom: 10px;
}

.cardTable {
  width: 100%;
  border-collapse: collapse;
}

.cardTable th,
.cardTable td {
  text-align: center;
  border: 0.1px solid #ddd;
  font-size: 12px;
}

.cardTable th {
  padding: 8px 15px;
  background-color: rgb(39, 39, 39);
  color: white;
}

.cardTable th.firstCol,
.cardTable td.firstCol {
  width: 50%;
  /* First column width */
}

.cardTable td.firstCol {
  padding-left: 15px;
  padding-right: 15px;
}

.cardTable th.secondCol,
.cardTable td.secondCol {
  width: 50%;
  /* Second column width */
}

.cardTable td.secondCol {
  padding-left: 15px;
  padding-right: 15px;
}

.progressionCell {
  align-items: center;
  justify-content: space-between;
}

.progressionCell:hover {
  color: black !important;
  background-color: yellow !important;
  cursor: pointer !important;
}

.progressionCell:hover .watchVideoButton {
  color: black !important;
}

/* When the + icon is not present */
.progressBarContainer.noPlusIcon {
  margin-left: 25px;
  /* Add more space to account for missing icon */
}

.progressBar {
  height: 10px;
  width: 25%;
  background-color: rgb(201, 201, 201);
  border-radius: 5px;
  border: 1px solid black;
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-left: 10px;
}

.progressFill {
  height: 100%;
  transition: width 0.3s ease;
  color: #fff;
  border-radius: 5px;
  overflow: hidden;
}

.progressPercentageRight {
  font-size: 12px;
  color: inherit !;
  position: absolute;
  top: 0;
  right: -38px;
  background-color: transparent;
}

.progressPercentageLeft {
  font-size: 12px;
  color: inherit !;
  position: absolute;
  top: 0;
  left: -20px;
  background-color: transparent;
}

.progressionCell:hover .progressPercentageLeft:hover,
.progressionCell:hover .progressPercentageRight:hover {
  color: black !important;
}

.lockedIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* Ensure the icon takes the full width of the cell */
  height: 100%;
  /* Ensure the icon takes the full height of the cell */
  font-size: 15px;
}

.unlockedIcon {
  margin-right: 20px !important;
}

.plusIconUnlock {}


.watchVideoButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: auto;
  /* Ensures the button floats to the right */
}

.watchVideoIcon {
  font-size: 20px;
  float: right;
  /* Ensure the icon is aligned right in the button */
}

/* Styles for the video modal */
.videoPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  overflow: none
}

/* Style the content inside the modal to fit within the modal */
.videoPopup iframe {
  width: 100%;
  /* Ensure the iframe takes up the full width */
  height: 100%
}

.videoCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: white;
}

.comingSoonMessage {
  text-align: center;
  font-size: clamp(15px, 7vw, 30px);
  margin-top: 50px;
  color: #fff;
}

.comingSoonMessageSubtitle {
  text-align: center;
  font-size: clamp(12px, 5vw, 25px);
  margin-top: 20px;
  margin-bottom: 30px;
  color: #fff;
}

.logo {
  display: block;
  margin: 0 auto;
  width: clamp(200px, 20vw, 800px);
  margin-top: 30px;
}

.plusIcon {
  font-size: 20px;
  margin-left: 5px;
}

.plusIconButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Progress Modal Styles */
.progressModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.93);
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  /* Medium width */
  text-align: center;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.progressModalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  /* Ensure full viewport width */
  height: 100vh;
  /* Ensure full viewport height */
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  /* Transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalHeader {
  margin-bottom: 20px;
}

.modalBody {
  margin-bottom: 20px;
  width: 100%;
}

.inputField {
  width: 80%;
  padding: 10px;
  display: block;
  margin: 0 auto;
  /* Center horizontally */
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.modalFooter {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.saveButton {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.saveButton:hover {
  background-color: #218838;
}

.saveButton:disabled {
  background-color: #6c757d;
  /* Grey background for disabled state */
  cursor: not-allowed;
  /* Show a "not allowed" cursor */
}

.cancelButton {
  background-color: #e74c3c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.cancelButton:hover {
  background-color: #c0392b;
}

.uploadVideoLabel {
  display: block;
  margin: 0 auto;
  /* Center horizontally */
  margin-bottom: 20px;
  font: 12;
  color: white;
}

.uploadVideoInput {
  display: block;
  margin: 0 auto;
  /* Center horizontally */
  margin-bottom: 20px;
  padding: 5px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
}


@media screen and (max-width: 480px) {

  /* Further reduce text size */
  .card {
    font-size: 8px;
    /* Further reduce font size */
  }

  .cardTable th,
  .cardTable td {
    font-size: 8px;
    /* Further reduce text size in table */
  }

  /* Further reduce icons */
  .watchVideoIcon,
  .plusIcon {
    font-size: 12px;
  }

  .progressBarContainer.noPlusIcon {
    transform: translateX(-10px); /* Smaller shift for small screens */
  }

  .progressBar {
    width: 25%;
  }

  .cardTable td.secondCol {
    padding-left: 10px;
    padding-right: 5px;
  }

  .lockedIcon {
    font-size: 8px;
    /* Further reduce locked icon size */
  }

  .progressPercentageRight {
    font-size: 8px;
    right: -20px;
  }

  .progressPercentageLeft {
    font-size: 8px;
    left: -15px;
  }

  .cardTable th.firstCol,
  .cardTable td.firstCol {
    width: 55%;
    /* First column width */
  }

  .cardTable th.secondCol,
  .cardTable td.secondCol {
    width: 45%;
    /* Second column width */
  }


}

/* General responsive adjustments for larger screens */
@media (min-width: 769px) {
  .card {
    font-size: 14px;
    /* Slightly increase font size for better readability */
  }

  .cardTable th,
  .cardTable td {
    font-size: 14px;
    /* Increase text size in table */
  }

  .watchVideoIcon,
  .plusIcon {
    font-size: 24px;
    /* Increase icon size */
  }


  .progressPercentageRight {
    font-size: 14px;
    right: -45px;
    /* Adjust positioning for larger screens */
  }

  .progressPercentageLeft {
    font-size: 14px;
    left: -30px;
    /* Adjust positioning for larger screens */
  }

  .lockedIcon {
    font-size: 18px;
    /* Increase locked icon size */
  }


  .progressBar {
    height: 12px;
    width: 25%;
    margin-left: 30px !important;
    /* Slightly taller progress bar for better visibility */
  }

}
