/* Styles for the "Coming soon!" message and logo */
.coming-soon-message {
  text-align: center;
  font-size: clamp(12px, 10vw, 25px);
  margin-top: 30px; /* Adjust the margin as needed */
  color: #fff; /* Adjust the text color as needed */
}

.coming-soon-subtitle {
  text-align: center;
  font-size: clamp(10px, 10vw, 20px);
  margin-top: 20px; /* Adjust the margin as needed */
  margin-bottom: 30px; /* Adjust the margin as needed */
  color: #fff; /* Adjust the text color as needed */
}

.coming-soon-logo {
  display: block;
  margin: 0 auto;
  width: clamp(200px, 20vw, 800px);
  margin-top: 30px; /* Add margin to separate the logo from the text */
}
