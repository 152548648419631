.iconContainer {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Adjust this margin as needed for spacing between icon and text */
}

.icon {
  font-size: 15px; /* Adjust the font size as needed */
  margin-right: 10px; /* Adjust this margin as needed for spacing between icon and text */
}

.card {
  position: relative;
  padding: 10px;
  border-radius: 5px;
  min-width: 320px;
  margin: 0 auto;
  font-size: 12px;
  line-height: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: silver;
  margin-bottom: 50px;
}

.cardTable {
  width: 100%;
  border-collapse: collapse;
}

.cardTable th,
.cardTable td {
  text-align: center;
  border: 0.1px solid #ddd;
  font-size: 12px; 
  padding: 10px 0; 
}

/* Center align the table headers */
.cardTable th {
  text-align: center; 
  background-color: rgb(39, 39, 39); /* Set the background color for the user column */
  color: white;
}

/* Center align the icon and text in the header cells */
.levelHeader .iconContainer {
  justify-content: center;
}

/* Background colors for different levels */
.level-1 {
  background-color: black; /* Set the background color for Level 1 */
  color: white; /* Set the text color for Level 1 */
}

/* Styles for the text with hover effect */
.levelCell span {
  transition: color 0.3s, font-weight 0.3s; /* Smooth color and font-weight transition on hover */
}

.levelCell span:hover {
  color: rgb(0, 123, 255); /* Change the text color on hover to a nicer color */
  font-weight: bold; /* Make the text bold on hover */
}

/* Header styles */
.cardHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
  background-color: rgb(39, 39, 39);
  color: white;
  padding-top:20px;
  padding-bottom:5px;
}

.cardHeader img {
  height: 60px;
  margin-right: 20px;
  margin-top: -10px;
}

.cardHeader span {
  font-weight: bold;
  font-size: 18px;
}

.subtitle {
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 0px;
  color: rgb(255, 255, 255); /* Adjust the color as needed */
}

.subtitle2 {
  font-size: 12px;
  font-weight: bold;
  margin-top: 8px;
  color: rgb(218, 209, 43); /* Adjust the color as needed */
}

/* Styling for the download button */
.downloadButton {
  cursor: pointer;
  background-color: #ffffff; /* Change the background color as desired */
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: background-color 0.3s ease;
  margin-left: 20px;
  margin-bottom: 15px;
}

.downloadButton:hover {
  background-color: #717171; /* Change the background color on hover */
  color: rgb(255, 255, 255);
}

/* Styles for the video modal */
.videoPopup {
  position: fixed; /* Position the modal absolutely on the viewport */
  top: 50%; /* Center the modal vertically */
  left: 50%; /* Center the modal horizontally */
  transform: translate(-50%, -50%); /* Center the modal precisely */
  z-index: 1000000; /* Ensure the modal is on top of other elements */
  background-color: rgba(0, 0, 0, 0.9); /* Semi-transparent background */
  padding: 0px; /* Add padding for the video content */
  border-radius: 10px;
  width: 90%; /* Set the maximum width to 90% of the viewport width */
  height: clamp(50%, 75%, 100%);
  overflow: none; /* Add scrollbars if the content exceeds the max dimensions */
}

/* Style the content inside the modal to fit within the modal */
.videoPopup iframe {
  width: 100%; /* Ensure the iframe takes up the full width */
  height: 100%
}

/* Style for the close button */
.videoCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
  font-size: 24px;
  z-index: 1001; /* Ensure the close button is on top of the modal */
}

/* Styles for the "Coming soon!" message and logo */
.comingSoonMessage {
  text-align: center;
  font-size: clamp(15px, 10vw, 30px);
  margin-top: 30px; /* Adjust the margin as needed */
  color: #fff; /* Adjust the text color as needed */
}

.comingSoonMessageSubtitle {
  text-align: center;
  font-size: clamp(12px, 10vw, 25px);
  margin-top: 20px; /* Adjust the margin as needed */
  margin-bottom: 30px; /* Adjust the margin as needed */
  color: #fff; /* Adjust the text color as needed */
}

.logo {
  display: block;
  margin: 0 auto;
  width: clamp(200px, 20vw, 800px);
  margin-top: 30px; /* Add margin to separate the logo from the text */
}

.textSection {
  display: flex;
  justify-content: space-between; 
  align-items: left;
}

.textSection > div {
  margin-left: 10px; /* Adjust the left margin as needed */
  text-align: left;
  font-size: 10px;
  margin-top: 2px;
}

.level1Text {
  font-weight: bold; /* Make the text bold */
  font-size: 12px !important; /* Adjust the font size as needed */
  width: 60px !important;
  padding-left: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
}

/* Styles for text1 subsections */
.text_subsection_1 {
  /* Your styles for the first subsection of text1 here */
  width:100px;
}

.text_subsection_2 {
  /* Your styles for the second subsection of text1 here */
  width:100px;
}

.text_subsection_3 {
  /* Your styles for the third subsection of text1 here */
  width:100px
}

.watchVideoButton {
  background-color: transparent;
  border: none;
  text-decoration: none; /* Remove underlines */
  cursor: pointer;
  padding-top:0;
  padding-left: 60px;
}

.watchVideoButton:hover {
  color: #00fff7 !important; /* Change the color on hover if desired */
}

.watchVideoIcon {
  font-size: 20px; /* Adjust the icon size as needed */
}

@media (min-width: 768px) {
  .card {
    padding: 20px;
    min-width: 600px; /* Increase the card width for larger screens */
  }

  .cardHeader {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .cardHeader img {
    height: 100px; /* Increase header image size */
    margin-right: 30px;
  }

  .cardHeader span {
    font-size: 24px; /* Increase header title size */
  }

  .subtitle {
    font-size: 20px; /* Increase subtitle font size */
    margin-top: 10px;
  }

  .subtitle2 {
    font-size: 16px; /* Increase subtitle2 font size */
  }

  .downloadButton {
    width: 50px; /* Increase button size */
    height: 50px;
    font-size: 24px;
    margin-left: 30px;
  }

  .level1Text {
    margin-left:10px !important;
  }

  .text_subsection_1 {
    margin-left: 15px !important;
  }

  .text_subsection_1,
  .text_subsection_2,
  .text_subsection_3 {
    font-size: 16px !important; /* Adjust font size for subsections */
    white-space: nowrap !important;
    width:170px !important;
  }
  
  .textSection > div {
    margin-top: 5px;
  }

  .watchVideoIcon {
    font-size: 24px; /* Make the play icon larger */
  }

  .watchVideoButton {
    padding-left: 130px; /* Make the play icon larger */
  }

}