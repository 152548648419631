.modal {
  z-index: 100000 !important; /* Ensures the modal is on top */
}

.newuser-profile-card-container {
  max-width: 350px;
  margin: 20px auto;
  padding: 10px;
  background-color: silver;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: none;
}

.newuser-profile-card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: rgb(39, 39, 39);
  color: white;
  padding: 10px;
  border-radius: 0;
}

.newuser-profile-card-header img {
  height: 40px;
  margin-right: 10px;
}

.newuser-profile-card-header span {
  font-weight: bold;
  font-size: 16px;
}

.newuser-profile-card-content {
  padding: 20px;
  border-radius: 5px;
  background-color: rgb(39, 39, 39);
  color: white;
}

.newuser-content-container {
  margin-top: 10px;
  padding: 10px;
  background-color: rgb(39, 39, 39);
  border-radius: 5px;
}

.newuser-profile-form-group {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}

.newuser-profile-form-control {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  flex: 1;
  text-align: center;
}

.newuser-profile-form-control-social {
  margin-left: 30px;
  margin-right: 30px;
}

.newuser-btn-home-link {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 0;
  background-color: black;
  color: white;
  border-radius: 30px;
  text-decoration: none;
  border: 0.5px solid white;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 12px;
  text-align: center;
}

.newuser-btn-home-link:hover {
  background-color: white;
  color: black;
}

.newuser-avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newuser-avatar-label {
  position: relative;
  cursor: pointer;
}

.newuser-avatar-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: contain;
  border: 2px solid white;
  background: black;
  margin-right: 10px;
}

.newuser-avatar-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.newuser-avatar-label:hover .newuser-avatar-overlay {
  opacity: 1;
}

.newuser-avatar-edit-icon {
  color: white;
  font-size: 24px;
}

.newuser-handle-email-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  margin-left: 10px;
  width: 100%;
}

.newuser-tab-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.newuser-tab-button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 5px;
  font-size: 24px;
  color: white;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newuser-tab-button:hover {
  transform: scale(1.2);
}

.newuser-tab-icon {
  font-size: 24px;
}

.newuser-tab-content {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.newuser-disclaimer {
  text-decoration: underline;
  color: inherit;
}

.newuser-disclaimer:hover {
  color: rgb(255, 247, 186);
}

.newuser-small-text {
  font-size: 12px;
}

.newuser-image-adjust-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.newuser-crop-container {
  position: relative;
  width: 100%;
  height: 70vh;
  background: #333;
}

.newuser-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.newuser-slider {
  margin: 0 10px;
  width: 200px;
}

.newuser-crop-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newuser-mock-sign-in-btn {
  margin-bottom: 20px;
  display: block;
}

.newuser-noteStyleContainer {
  display: flex;
  justify-content: center;
}

.newuser-noteStyle {
  display: flex;
  align-items: center;
}

.newuser-infoIcon {
  margin-right: 8px;
}

.newuser-center-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}
