
/******* Footer ********/
.footer {
    background-color: black;
    color: rgb(255, 255, 255);
    padding: 10px;
    padding-bottom: 15px;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .footer-message {
    font-size: 12px;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
  }

  .footer-message a{
    color: white;
    margin-left:5px;
    margin-right: 5px;
  }
  
  
  .logo-text-footer {
    height: 22px;
    padding-left: 0px;
    padding-right: 0px;
  }
  
  /* Style for individual social media icons */
  .social-media-icon {
    width: 20px;
    font-size: 15px; /* Set the width for each icon */
    margin: 0 0px; /* Add margin to separate the icons */
    transition: transform 0.3s ease-in-out; /* Add a smooth hover effect */
    color: white;
  }
  
  /* Style for the icons on hover */
  .social-media-icon:hover {
    transform: scale(1.2); /* Increase the size of the icon on hover */
    cursor: pointer; /* Add a pointer cursor on hover */
  }

