.card {
  position: relative;
  padding: 10px;
  border-radius: 5px;
  min-width: 320px;
  margin: 0px 20px;
  background-color: silver;
  /* Use the lighter grey background */
  font-size: 12px;
  line-height: 1.5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 100px !important;
  overflow: visible;
  /* Allow child elements to overflow */
}

.cardTable {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  background-color: rgb(39, 39, 39);
  /* Table background color */
}

.cardTable th,
.cardTable td {
  padding: 10px;
  border: 1px solid #ddd;
  color: white;
  vertical-align: middle;
  height: 100%;
  /* Ensures consistent height across rows */
}

.cardTable th {
  background-color: rgb(18, 18, 18);
  /* Dark grey for header background */
}

.user {
  text-align: center;
  /* Center-align the content */
  padding: 10px;
  /* Match padding with other cells */
}

.avatar,
.badge {
  width: 40px;
  height: 40px;
  display: block;
  margin: 0 auto;
}

.avatar {
  border: 1px solid white;
  background-color: black;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  object-fit: cover;
  cursor: pointer;
}

.avatar:hover {
  transform: scale(4);
  z-index: 1000;
}

.badge:hover {
  transform: scale(4);
  z-index: 1000;
}

@media (max-width: 480px) {
  .avatar:hover {
    transform: scale(2);
    z-index: 1000;
  }
  
  .badge:hover {
    transform: scale(2);
    z-index: 1000;
  }
}

.username,
.level span {
  display: block;
  /* Ensure text behaves the same for both */
  margin-top: 6px;
  /* Consistent spacing below avatar/badge */
  font-size: 12px;
  /* Match font size */
  line-height: 1.2;
  /* Adjust line height for better spacing */
  text-align: center;
  /* Center-align text */
}

.duration {
  display: block;
  /* Ensure text behaves the same for both */
  margin-bottom: 6px;
  /* Consistent spacing below avatar/badge */
  font-size: 12px;
  /* Match font size */
  line-height: 1.2;
  /* Adjust line height for better spacing */
  text-align: center;
  /* Center-align text */
}

.progressBar {
  width: 80%;
  height: 10px;
  background-color: #555;
  border-radius: 5px;
  overflow: hidden;
  margin: 5px auto;
}

.progressFill {
  height: 100%;
  transition: width 0.3s ease;
}

.progressPercentage {
  font-size: 12px;
  margin-top: 5px;
}

.levels {
  text-align: center;
}

.levelsContainer {
  text-align: center;
  /* Center rows of levels */
}

.levelRow {
  margin-bottom: 5px;
  /* Space between rows */
}

.levelCircle {
  display: inline-block;
  margin: 3px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}


/* Styles for the video modal */
.videoPopup {
  position: fixed;
  /* Position the modal absolutely on the viewport */
  top: 50%;
  /* Center the modal vertically */
  left: 50%;
  /* Center the modal horizontally */
  transform: translate(-50%, -50%);
  /* Center the modal precisely */
  z-index: 1000000;
  /* Ensure the modal is on top of other elements */
  background-color: rgba(0, 0, 0, 0.9);
  /* Semi-transparent background */
  padding: 0px;
  /* Add padding for the video content */
  border-radius: 10px;
  width: 90%;
  /* Set the maximum width to 90% of the viewport width */
  height: clamp(50%, 75%, 100%);
  overflow: none;
  /* Add scrollbars if the content exceeds the max dimensions */
}

/* Style the content inside the modal to fit within the modal */
.videoPopup iframe {
  width: 100%;
  /* Ensure the iframe takes up the full width */
  height: 100%
}

/* Style for the close button */
.videoCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
  font-size: 24px;
  z-index: 1001;
  /* Ensure the close button is on top of the modal */
}


/* Styles for the "Coming soon!" message and logo */
.comingSoonMessage {
  text-align: center;
  font-size: clamp(15px, 10vw, 30px);
  margin-top: 30px;
  /* Adjust the margin as needed */
  color: #fff;
  /* Adjust the text color as needed */
}

.comingSoonMessageSubtitle {
  text-align: center;
  font-size: clamp(12px, 10vw, 25px);
  margin-top: 20px;
  /* Adjust the margin as needed */
  margin-bottom: 30px;
  /* Adjust the margin as needed */
  color: #fff;
  /* Adjust the text color as needed */
}

.logo {
  display: block;
  margin: 0 auto;
  width: clamp(200px, 20vw, 800px);
  margin-top: 30px;
}

.heartIcon {
  cursor: pointer;
  font-size: 24px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.unfavorited {
  color: black;
  /* Black heart outline */
  background-color: transparent;
  /* Transparent inside */
  -webkit-text-stroke: 1px white;
  /* White outline around the heart */
}

.favorited {
  color: white;
  /* Filled white heart */
  background-color: transparent;
  /* No background */
  -webkit-text-stroke: none;
  /* Remove outline */
}

.pagination {
  text-align: center;
  margin-top: 10px;
}

.paginationButton {
  background-color: #292929;
  /* Slightly darker for active buttons */
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.paginationButton:hover {
  background-color: #666;
  /* Even darker on hover for active buttons */
}

.paginationButton:disabled {
  background-color: #888;
  /* Lighter grey for disabled buttons */
  color: #ccc;
  /* Dimmed text color for disabled buttons */
  cursor: not-allowed;
}

.paginationInfo {
  color: white;
  margin: 0 10px;
}

/* Media query for tablets */
@media (max-width: 768px) {
  .levelsContainer .levelCircle:nth-last-child(n+3) {
    display: none; /* Show only the highest 2 circles */
  }

  .card {
    padding: 8px; /* Reduce padding */
    margin: 10px 10px; /* Reduce margin */
    font-size: 11px; /* Reduce font size */
  }

  .cardTable th,
  .cardTable td {
    padding: 8px; /* Reduce cell padding */
  }

  .avatar,
  .badge {
    width: 35px; /* Smaller avatar size */
    height: 35px;
  }

  .progressBar {
    width: 70%; /* Slightly narrower progress bar */
    height: 8px; /* Reduced height */
  }

  .levelCircle {
    width: 25px; /* Smaller circle size */
    height: 25px;
    font-size: 10px; /* Smaller text inside circles */
    margin: 2px; /* Tighter spacing */
  }

  .paginationButton {
    padding: 8px 15px; /* Reduce button size */
    font-size: 12px; /* Reduce button font size */
  }

  .heartIcon {
    font-size: 20px; /* Slightly smaller heart icon */
  }
}

/* Media query for phones */
@media (max-width: 480px) {
  .card {
    padding: 6px; /* Further reduce padding */
    margin: 5px 5px; /* Tighten margin */
    font-size: 10px; /* Smaller font size */
  }

  .cardTable th,
  .cardTable td {
    padding: 6px; /* Further reduce cell padding */
  }

  .avatar,
  .badge {
    width: 30px; /* Even smaller avatar size */
    height: 30px;
  }

  .progressBar {
    width: 60%; /* Narrower progress bar */
    height: 6px; /* Reduced height */
  }

  .levelCircle {
    width: 20px; /* Smaller circle size */
    height: 20px;
    font-size: 8px; /* Smaller text inside circles */
    margin: 1px; /* Minimal spacing */
  }

  .paginationButton {
    padding: 6px 10px; /* Further reduce button size */
    font-size: 10px; /* Smaller button font size */
  }

  .heartIcon {
    font-size: 18px; /* Slightly smaller heart icon */
  }

  .levelsContainer .levelCircle:nth-last-child(n+2) {
    display: none; /* Show only the highest circle */
  }
}

.filterRow {
  background-color: #121212; /* Dark background */
  padding: 10px; /* Adjust padding for better spacing */
  border-radius: 5px; /* Smooth border */
  display: flex; /* Use flex for layout */
  align-items: center; /* Center items vertically */
  gap: 15px; /* Space between elements */
  justify-content: space-between; /* Spread elements evenly */
}

.filterRow button {
  background: none;
  color: gray; /* Default gray text color */
  border: none; /* Remove borders */
  font-size: 14px;
  cursor: pointer;
}

.filterRow button:hover {
  color: goldenrod; /* Highlight on hover */
}

@media (hover: none) {
  .filterRow button:hover {
    color: inherit; /* Disable hover styles */
  }
  .heartIcon:hover,
  .heartIcon:active {
    color: inherit; /* Prevent hover and active styles on touch */
  }
}

.filterRow .active {
  color: white; /* White text for active state */
}

.filterRow input[type="text"] {
  width: 150px; /* Default width for search box */
  background-color: #292929; /* Dark input background */
  color: gray; /* Gray text color */
  border: 1px solid #444; /* Subtle border */
  padding: 5px 10px; /* Add padding */
  border-radius: 5px;
  font-size: 14px;
}

.filterRow input[type="text"]::placeholder {
  color: gray; /* Placeholder color */
}

.filterRow .dropdown {
  position: relative; /* Position for dropdown arrow */
  display: inline-block; /* Align inline with other buttons */
  cursor: pointer;
  color: gray; /* Default gray text */
  font-size: 14px;
}

.filterRow .dropdown select {
  background-color: transparent; /* Transparent background */
  color: gray; /* Gray text color */
  font-size: 14px; /* Match text size */
  border: none; /* Remove border */
  cursor: pointer;
}

.filterRow .dropdown option {
  background-color: #292929; /* Dark background for options */
  color: white; /* White text */
}

/* Media query for tablets */
@media (max-width: 768px) {
  .filterRow {
    padding: 8px; /* Reduce padding */
    gap: 10px; /* Reduce gaps between elements */
  }

  .filterRow button {
    font-size: 12px; /* Smaller font size for buttons */
  }

  .filterRow input[type="text"] {
    width: 120px; /* Smaller width for the search box */
    font-size: 12px; /* Smaller font size for input */
    padding: 4px 8px; /* Reduced padding */
  }

  .filterRow .dropdown select {
    font-size: 12px; /* Smaller dropdown font size */
  }
}

/* Media query for phones */
@media (max-width: 480px) {
  .filterRow {
    padding: 6px; /* Further reduce padding */
    gap: 8px; /* Smaller gap */
  }

  .filterRow button {
    font-size: 10px; /* Even smaller font size for buttons */
  }

  .filterRow input[type="text"] {
    width: 100px; /* Narrower search box */
    font-size: 10px; /* Smaller font size for input */
    padding: 3px 6px; /* Minimal padding */
  }

  .filterRow .dropdown select {
    font-size: 10px; /* Smaller dropdown font size */
  }
}


@media (min-width: 768px) {
  .card {
    padding: 20px; /* Increase padding */
    border-radius: 10px; /* Smoother corners */
    min-width: 400px; /* Wider card */
    font-size: 16px; /* Increase font size */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); /* Stronger shadow */
  }

  .avatar,
  .badge {
    width: 60px; /* Larger avatar size */
    height: 60px;
  }

  .username,
  .level span,
  .duration {
    font-size: 14px; /* Larger text size */
    line-height: 1.5; /* Improved spacing */
  }

  .progressBar {
    width: 90%; /* Wider progress bar */
    height: 15px; /* Taller progress bar */
  }

  .progressPercentage {
    font-size: 14px; /* Larger percentage text */
  }

  .levelCircle {
    width: 40px; /* Larger circle size */
    height: 40px;
    font-size: 14px; /* Larger text inside circles */
    margin: 5px; /* Adjust spacing */
  }

  .paginationButton {
    padding: 12px 25px; /* Larger buttons */
    font-size: 14px; /* Larger font size for buttons */
  }

  .heartIcon {
    font-size: 28px; /* Larger heart icon */
  }
}