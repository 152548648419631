.disclaimer-link {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 18px; /* Adjust the font size as needed */
  color: white; /* Text color for non-selected buttons */
  background-color: black; /* Background color for non-selected buttons */
  text-decoration: none;
}

/* Style for selected button */
.disclaimer-link.selected {
  color: black; /* Text color for selected button */
  background-color: white; /* Background color for selected button */
}

.left {
  text-align: left;
  font-size: 12px;
  line-height: 1.4;
}

.left p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.left ul li {
  margin-bottom: 5px; /* Adjust this value as needed */
  margin-top: 5px; /* Adjust this value as needed */
}

.left h3 {
  font-size: 16px;
}

.left h2 {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  font-size: 18px;
  text-decoration: underline;
  text-transform: capitalize;
}

.left h1 {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 18px;
  text-decoration: underline;
  text-transform: uppercase;
}

.left h1.title {
  text-align: center;
  margin-top:140px;
}

.disclaimer-horizontal-rule {
  width: 75%;
  border: 0.3px solid rgba(255, 255, 255, 0.8); /* White color with 50% transparency */
  margin: 15px auto; /* Center the horizontal rule within its parent */
}

.fixed-header {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    background-color: black; /* Set the background color you want */
  }