/******* Navigation ********/

.logo {
  height: 50px;
}

.logo-text {
  height: 30px;
  padding-left: 10px;
}

.bartop {
  position: fixed;
  width: 100%;
  background-color: rgb(0, 0, 0, 1) !important;
  padding-right: 20px;
  padding-left: 20px;
  z-index: 100000 !important;
}

.navbar-nav .nav-link {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
}

.nav-link:hover {
  color: rgb(255, 247, 186);
}

.nav-link:not(:hover) {
  color: grey;
}


.nav-icon {
  font-size: 20px;
  transform: translate(-7px, -6px);
  display: inline-block;
  vertical-align: middle;
  color:#fff !important;
}

.nav-icon-custom-events {
  height:20px;
  padding-right:2px;
  padding-left:2px;
}

.nav-icon-custom-hspu {
  height:30px;
  padding-right:2px;
  padding-left:2px;
}

@media (min-width: 768px) {
  .navbar-nav .nav-link {
    font-size: 16px; /* Increased font size */
  }

  .nav-icon {
    font-size: 24px; /* Increased icon size */
  }

  .nav-icon-custom-events {
    height: 24px; /* Slightly larger custom event icons */
  }

  .nav-icon-custom-hspu {
    height: 34px; /* Slightly larger custom HSPU icons */
  }

  .logo {
    height: 50px; /* Increased logo size */
  }

}